// consumer_web_policyConfig_addGenerationCarPolicy 添加代叫用车差标
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://yapi.yinzhitravel.com/project/52/interface/api/15180
const consumer_web_policyConfig_addGenerationCarPolicy = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/web/policyConfig/addGenerationCarPolicy',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_web_policyConfig_addGenerationCarPolicy;
